

import {Component, Vue} from 'vue-property-decorator';
import {AxiosRestApplicationClient, Oauth2ServersInfo} from '../services/client';
import {AxiosResponse} from "axios";
import SsoCard from "./components/SsoCard.vue";

@Component({
  components: {SsoCard}
})
export default class EnrollWithIdent extends Vue {

  public ready = false;
  public oauth2Servers: Oauth2ServersInfo[] = [];
  public oauth2ServersGroups: Record<any, Oauth2ServersInfo[]> = Object.create(null);
  public frontPropertiesReady = false;
  private httpClient = new AxiosRestApplicationClient('/');

  public created(): void {
    this.$languages.changeLanguage(<string>this.$route.query.lang, <string>this.$route.query.tenant_id).then((lang: string) => {
      this.$vuetify.lang.current = lang;
      console.log('Using language:', lang);
      this.ready = true;
    });

    let that = this;

    //get sso choices
    this.httpClient.info$GET$csc_v0_front({
      lang: <string>this.$route.query.lang,
      tenant: <string>this.$route.query.tenant_id
    }).then((response: AxiosResponse) => {
      that.oauth2Servers = response.data.oauth2Servers;
      that.oauth2ServersGroups = response.data.oauth2ServersGroups;
      that.frontPropertiesReady = true;

    });
  }

  public submit(oauthServiceId: string, newUser: boolean): void {
    let url = '/csc/v0/oauth2/select-sso?' +
        'response_type=' + this.$route.query.response_type +
        '&client_id=' + this.$route.query.client_id +
        '&redirect_uri=' + this.$route.query.redirect_uri +
        '&lang=' + this.$route.query.lang +
        '&state=' + this.$route.query.state +
        '&consent_id=' + this.$route.query.consent_id +
        '&tenant_id=' + this.$route.query.tenant_id +
        '&sso_id=' + oauthServiceId +
        '&new_user=' + newUser;
    if (this.$route.query.account_token) {
      url += '&account_token=' + this.$route.query.account_token;
    }
    location.replace(url);
  }
}



import {Component, Prop, Vue} from 'vue-property-decorator';
import {Oauth2ServersInfo} from "../../services/client";

@Component
export default class SsoCard extends Vue {
  @Prop({type: String, required: true}) private title: string | undefined
  @Prop({type: Array, required: true}) private items: Array<Oauth2ServersInfo> | undefined

  private selectedSso = {} as Oauth2ServersInfo

  public mounted(): void {
    if (this.items && this.items.length > 0) {
      this.selectedSso = this.items[0]
    }
  }

}
